import {
    AppstoreOutlined,
    BarsOutlined
} from '@ant-design/icons';
import { App as AntApp, Layout, Menu, Avatar, Space, Col, Row} from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import AnnotationPage from './pages/Annotation';
import LoginPage from './pages/LoginPage';
import TasksPage from './pages/Tasks';
import useToken from './hooks/useToken';
import AuthService from './API/AuthService';
import ProtectedRoute from './components/ProtectedRoute';
import 'antd/dist/reset.css';
import Home from './pages/Home';
import './styles/App.css';

const { Sider, Content, Header } = Layout;


const App = () => {
    const [collapsed, setCollapsed] = useState(false);
    const { token, setToken } = useToken();
    const [user, setUser] = useState()
    const navigate = useNavigate();

    useEffect(() => {
        if (token) {
            AuthService.currentUser(token).then(data => setUser(data.data.data))
        }
    }, [token])

    return (
        <AntApp>
            <Layout style={{ minHeight: '100vh' }}>
                <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
                    <Menu
                        theme="dark"
                        mode="inline"
                        defaultSelectedKeys={['1']}
                        items={[
                            {
                                key: '2',
                                icon: <AppstoreOutlined />,
                                label: <Link to='/'>Home</Link>,
                            },
                            {
                                key: '1',
                                icon: <BarsOutlined />,
                                label: <Link to='/tasks'>Tasks</Link>,
                            },
                        ]}
                    />
                </Sider>
                <Layout className="site-layout">
                    <Header style={{ padding: 0, background: 'rgb(255, 255, 255)' }}>
                        <Row align='middle' justify="end">
                            <Col justify='end' span={23}>
                            </Col>
                            <Col justify='end' span={1}>
                                <Space>
                                    { user && <Avatar shape="square">{ user.username[0].toUpperCase() }</Avatar>}
                                </Space>
                            </Col>
                        </Row>

                    </Header>
                    <Content
                        style={{
                            padding: 10,
                            minHeight: 280,
                            backgroundColor: 'rgb(240, 242, 245)'
                        }}
                    >
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/login" element={<LoginPage />} />
                            <Route path="/tasks" element={<ProtectedRoute><TasksPage /></ProtectedRoute>} />
                            <Route path="/task/:taskId/item/:itemId" element={<ProtectedRoute><AnnotationPage /></ProtectedRoute>} />
                        </Routes>
                    </Content>
                </Layout>
            </Layout>
        </AntApp>
    );
};

export default App