import {
    Routes,
    Route,
    NavLink,
    Navigate,
    useNavigate,
} from 'react-router-dom';
import useToken from '../hooks/useToken';


const ProtectedRoute = ({ children }) => {
    const { token, setToken } = useToken();

    if (!token) {
        return <Navigate to="/login" replace={true} />
    }

    return children;
};

export default ProtectedRoute