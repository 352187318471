import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { App, Button, Form, Input } from 'antd';
import axios from "axios";
import React, { useEffect }from 'react';
import { useNavigate } from "react-router-dom";
import '../styles/App.css';


const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 5000
});


function Login({ setToken, token }) {
    const { message } = App.useApp();
    const navigate = useNavigate();

    const loginUser = async credentials => {
        try {
            const response = await axiosInstance.post('users/login', credentials);

            return response.data.data;
        } catch (e) {
            message.error(e.message);
        }
    }

    useEffect(() => {
        if (token) {
            navigate('/tasks');
        }
    }, [token])

    const handleSubmit = async values => {
        const token = await loginUser(values);
        if (token) {
            setToken(token);
            message.success('Success!');
        }
    }

    return (
        <div className="login-wrapper">
            <Form
                name="normal_login"
                className="login-form"
                onFinish={handleSubmit}
            >
                <Form.Item
                    name="username"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Username!',
                        },
                    ]}
                >
                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Password!',
                        },
                    ]}
                >
                    <Input
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="Password"
                    />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                        Log in
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default Login