import React, { useState } from 'react';
import {
    CarryOutOutlined,
    BarsOutlined,
    AppstoreOutlined
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';

const { Footer, Header, Sider, Content } = Layout;

const HomePage = () => {

    return (
        <div>Home Page</div>
    );
};

export default HomePage;
