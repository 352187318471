import { LeftOutlined, LoadingOutlined, RightOutlined, ClockCircleOutlined, StopOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Col, Layout, Row, Select, Space, Badge } from 'antd';
import { zonedTimeToUtc } from 'date-fns-tz';
import formatDistance from 'date-fns/formatDistance';
import parseJSON from 'date-fns/parseJSON';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import TasksService from "../../API/TasksService";
import ItemsService from '../../API/ItemsService';
const { Header, Content, Sider } = Layout;
const { Option } = Select;


function NavigationToolBar({ item, saveAnnotation, resetAnnotation }) {
  const { taskId, itemId } = useParams()
  const [offset, setOffset] = useState(0);
  const [prevItemId, setPrevItemId] = useState(itemId);
  const [totalItems, setTotalItems] = useState(0);
  const [nextItemId, setNextItemId] = useState();
  const navigate = useNavigate();

  const getPaginationData = async () => {
    const res = await TasksService.getNextItemId(taskId, 1, offset, prevItemId);

    if (res.success) {
      // if (offset > 0) {

      // }
      setTotalItems(res.data.total_items);
      setNextItemId(res.data.next_item_id);
      setPrevItemId(res.data.prev_item_id);
    }
  }

  useEffect(() => {
    resetAnnotation()
    getPaginationData();
  }, [offset]);

  const clickNext = async () => {
    if (nextItemId == null) {
      return
    }
    setPrevItemId(itemId);

    navigate(`/task/${taskId}/item/${nextItemId}`);
    setOffset(offset + 1);
  };

  const clickPrev = async () => {
    if (prevItemId == null) {
      return
    }

    navigate(`/task/${taskId}/item/${prevItemId}`);

    if (offset > 0) {
      setOffset(offset - 1);
    }
  };

  const skipClick = async () => {
    await ItemsService.changeState(itemId, 'skipped');
    clickNext();
  };

  const getDiffUpdatedAt = (updated_at) => {
    return formatDistance(
      zonedTimeToUtc(parseJSON(updated_at), 'Europe/Kyiv'),
      zonedTimeToUtc(new Date()), { addSuffix: true }
    )
  }


  const GetStateBadge = (state) => {
    const mapStateToBadge = {
      'new': <Badge status="default" text="New" />,
      'skipped': <Badge status="warning" text="Skipped" />,
      'annotated': <Badge status="processing" text="Annotated" />,
      'error': <Badge status="error" text="Error" />,
      'deleted': <Badge status="error" text="Deleted" />,
    }

    return mapStateToBadge[state]
  }

  const headerStyle = {
    color: '#fff',
    height: 64,
    paddingInline: 50,
    lineHeight: '64px',
    backgroundColor: '#7dbcea',
    position: 'sticky',
    zIndex: 1,
    width: '100%',
  };

  const _saveAnnotation = async () => {
    try {
      await saveAnnotation()
    } catch (e) {
      return
    }

    await clickNext()
  }

  const ButtonSaveBlock = ({ item }) => {

    const props = {
      'type': "primary",
      'icon': (< SaveOutlined />)
    }

    if (!['new'].includes(item.state)) {
      props['disabled'] = true
    }

    return (
      <Button {...props} onClick={() => _saveAnnotation()} size="large">SUBMIT</Button>
    )

  }
  const antLoadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return (
      <>
        <Header style={headerStyle}>
            <Row justify="left" >
              <Col span={18}>
                <Space wrap>
                    <Button disabled={offset < 1} onClick={clickPrev} type="primary" icon={<LeftOutlined />} />
                    <span>Item: {offset} / ({ totalItems })</span>
                    {nextItemId && <Button onClick={clickNext} type="primary" icon={<RightOutlined />} />}

                    {item && item.state == 'new' && <Button danger onClick={skipClick} type="primary" icon={<StopOutlined />} >Skip</Button>}
                    {item && item.state && GetStateBadge(item.state)}
                    {item && item.updated_at && (<><ClockCircleOutlined className="timeline-clock-icon" /> Updated at: {getDiffUpdatedAt(item.updated_at)}</>)}
                </Space>
              </Col>
              <Col span={6} >
                <Space wrap align="end">
                  {item && <ButtonSaveBlock item={item} />}
                </Space>
              </Col>
            </Row>
        </Header>
      </>
  );
}

export default NavigationToolBar;