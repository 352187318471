import React, { useEffect, useState, useCallback } from 'react';
import { Table, Progress } from 'antd';
import TasksService from '../../API/TasksService';
import { Link } from "react-router-dom";
import { useFetching } from "../../hooks/useFetching";
import { zonedTimeToUtc } from 'date-fns-tz';
import { useParams } from "react-router-dom";


const ProgressBar = ({ item }) => {
    const percent = Math.round((item.annotated_items + item.skipped_items) / item.total_items * 100)

    return (
        <Progress percent={percent} size="small" />
    )
}


const columns = [
    {
        title: 'GO',
        dataIndex: 'task_alias',
        key: 'task_alias',
        render: (text, record) => record?.first_item_id && <Link to={`/task/${record._id}/item/${record.first_item_id}`}>{text}</Link>,
    },
    // {
    //     title: 'State',
    //     dataIndex: 'state',
    //     key: 'state',
    // },
    {
        title: 'Progress',
        dataIndex: 'state',
        key: 'state',
        render: (text, record) => <ProgressBar item={record} />
    },
    {
        title: 'Assigne',
        dataIndex: 'assigned_to',
        key: 'assigned_to',
    },
    {
        title: 'Total',
        dataIndex: 'total_items',
        key: 'total_items',
    },
    {
        title: 'Annotated',
        dataIndex: 'annotated_items',
        key: 'annotated_items',
    },
    {
        title: 'Skipped',
        dataIndex: 'skipped_items',
        key: 'skipped_items',
    },
    {
        title: 'Created at',
        dataIndex: 'created_at',
        key: 'created_at',
        render: (text, record) => zonedTimeToUtc(record.created_at, 'Europe/Kyiv').toLocaleString(),
    },
]

const TasksTable = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [tableParams, setTableParams] = useState({
        pagination: {
          current: 1,
          pageSize: 10,
        },
    });

    const fetchTasks = useFetching(async () => {
        const response = await TasksService.getAllTasks(
            tableParams.pagination.pageSize,
            (tableParams.pagination.current - 1) * tableParams.pagination.pageSize
        );
        setData(response.data.data.tasks);
        setTableParams({
            pagination: {
                ...tableParams.pagination,
                total: response.data.data.meta.total_tasks,
                pageSize: response.data.data.meta.limit,
                current: response.data.data.meta.offset / response.data.data.meta.limit + 1,
            }
        })
    }, setLoading, setError)

    useEffect(() => {
        fetchTasks()
    }, [JSON.stringify(tableParams)]);

    const handleTableChange = (pagination, filters, sorter) => {
        console.log(pagination  )
        setTableParams({
            pagination: {
                ...pagination,
            }
        });

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
          setData([]);
        }
      };

    return (
        <Table
            rowKey={(record) => record._id}
            dataSource={data}
            columns={columns}
            loading={loading}
            pagination={tableParams.pagination}
            onChange={handleTableChange}
        />
    );
};

export default TasksTable;