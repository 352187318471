import axiosInstance from "./common";


export default class TasksService {

    static async getAllTasks(limit, offset) {
        const response = await axiosInstance.get('/tasks', {
            params: {
                'limit': limit,
                'offset': offset
            }
        })

        return response
    }

    static async getTaskById(taskId) {
        const response = await axiosInstance.get(`/tasks/${taskId}`)

        return response
    }

    static async getItemByIdAndTaskId(itemId, taskId) {
        const response = await axiosInstance.get(`/task/${taskId}/item/${itemId}`)

        return response
    }

    static async getNextItemId(taskId, limit, offset, prevItemId) {
        const response = await axiosInstance.get(`/task/${taskId}/get_next_item`, {
            params: {
                'limit': limit,
                'offset': offset,
                'prev_item_id': prevItemId,
            }
        })

        return response.data
    }

    static async changeStateForTask(taskId, state) {
        const response = await axiosInstance.patch('/tasks/change_state',
            {
                'task_alias': taskId,
                'state': state
            }
        )

        return response
    }

    static async getGalleryCoinsByTaskId(taskId, limit) {
        const response = await axiosInstance.get(`/tasks/${taskId}/gallery_coins_by_task`, {
            params: {
                'limit': limit
            }
        })

        return response
    }

    static async saveAnnotatedTask(taskId, seletedItems) {
        const response = await axiosInstance.post(`/tasks/${taskId}/save_annotations`,
            {
                'annotation_image_ids': seletedItems
            }
        )

        return response
    }

    static async saveTag(taskId, tag) {
        const response = await axiosInstance.post(`/tasks/${taskId}/add_tags`,
            {
                'tags': [tag]
            }
        )

        return response
    }
}