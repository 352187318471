import React, { useState } from 'react';
import Login from '../components/Login';
import { App } from 'antd';
import useToken from '../hooks/useToken';


const LoginPage = () => {
    const { token, setToken } = useToken();

    return (
        <App>
            <Login setToken={setToken} token={token} />
        </App>
    );
};

export default LoginPage;