import { Col, Image, Layout, Row, Typography, Input, Space } from 'antd';
import { PictureOutlined } from '@ant-design/icons';
import React from 'react';
import cl from "./Annotation.module.css";
import CategorySelector from './CategorySelector';
import MetaBlock from './MetaBlock';
const { Text, Link, Paragraph } = Typography;

const { Header, Content, Sider, Collapse } = Layout;


function Annotator({ item, annotation, setAnnotation }) {
    if (!item) {
        return ''
    }

    const changeInput = (e) => {
        setAnnotation(ann => ({ ...ann, 'custom_field': e.target.value }))
    }

    return (
        <>
            <Content>
                <Row>
                    <Col span={12}>
                        <Content className={cl.annContainer}>
                            <div className={cl.annImage}>
                                <Paragraph copyable={{'text': item?.img_url} }><Text type="secondary"><PictureOutlined /> {item._id}</Text></Paragraph>
                                <Image
                                    fallback={true}
                                    height={480}
                                    src={item?.img_url}
                                />
                            </div>
                        </Content>
                    </Col>
                    <Col span={12}>
                        <Content className={cl.annContainer}>
                            <Space direction="vertical">
                                <CategorySelector
                                    category={annotation?.category}
                                    onSelectCategory={(v) => setAnnotation(ann => ({ ...ann, 'category': v }))}
                                />
                                <Input
                                    value={annotation?.custom_field}
                                    onChange={changeInput}
                                    placeholder="Custom"
                                />
                                {/* <MetaBlock item={item} /> */}
                            </Space>
                        </Content>
                    </Col>
                </Row>
            </Content>
        </>
    );
}

export default Annotator;