import axiosInstance from "./common";


export default class ItemsService {
    static async changeState(itemId, state) {
        const response = await axiosInstance.patch(`/item/${itemId}/change_state`,
            {
                'state': state
            }
        )

        return response
    }
}