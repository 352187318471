import axios from "axios";


const getToken = () => {
    const tokenString = sessionStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken?.token
};

const axiosInstance = () => {

    return axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: 5000,
        headers: {
            "Authorization": "Basic " + getToken()
        }
    });
}

export default axiosInstance();