import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AnnotationService from "../API/AnnotationService";


const initialAnnotation = {
    'category': '',
    'custom_field': ''
}

export const useAnnotation = () => {
    const { taskId, itemId } = useParams()
    const [annotation, setAnnotation] = useState(initialAnnotation);

    
    const saveAnnotation = async () => {
        const res = await AnnotationService.saveAnnotation(itemId, annotation)
        if (res.success) {
            setAnnotation(initialAnnotation)
        }
    }

    return [annotation, setAnnotation, saveAnnotation]
}
