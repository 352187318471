import cl from "./Annotation.module.css";

function MetaBlock({ item }) {
    if (!item) {
        return ''
    }

    return (
        <div className={cl.metaBlockContainer} style={{color: 'gray'}} >
            <h3>Meta info: </h3>
            <h4>Predictions</h4>
            <ul>
                {item.meta.predicted_by_model.map((pred, i) => <li key={i}>{pred.name.replace('_', ' ')} ({pred.probability.toFixed(2)})</li>)}
            </ul>
        </div>
    )
}

export default MetaBlock;