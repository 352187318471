import React, { useState, useEffect } from 'react';

import { AutoComplete, Input } from 'antd';
import data_categories from '../../data/plantin-categories.json';


const searchResult = (query) =>
    data_categories.filter(v => v.label.toLowerCase().indexOf(query.toLowerCase()) !== -1).map(v => {
      return {
        value: v.idx,
        label: (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <span>{v.label}</span>
          </div>
        ),
      };
    });


function CategorySelector({ onSelectCategory, category }) {
    const [options, setOptions] = useState([]);
    const [val, setVal] = useState(category)
    const handleSearch = (value) => {
      setOptions(value ? searchResult(value) : []);
    };
    const onSelect = (value) => {
      onSelectCategory(value)
    };

    useEffect(() => {
      setVal(category)
    }, [category]);

    return (
      <>
        <AutoComplete
            dropdownMatchSelectWidth={252}
            style={{
                width: 300,
            }}
            allowClear={true}
            value={val}
            onChange={setVal}
            options={options}
            onSelect={onSelect}
            onSearch={handleSearch}
        >
            <Input.Search size="large" placeholder="species" enterButton />
        </AutoComplete>
      </>
    );
}

export default CategorySelector;