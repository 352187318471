import React, { useState } from 'react';
import TasksTable from '../components/Tasks/TasksTable';


const TasksPage = () => {
    return (
        <TasksTable />
    );
};

export default TasksPage;