import { App as AntApp, Layout, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import TasksService from "../../API/TasksService";
import { useFetching } from "../../hooks/useFetching";
import cl from "./Annotation.module.css";
import NavigationToolBar from './NavigationToolBar';
import Annotator from './Annotator';
import { useAnnotation } from '../../hooks/useAnnotation';


function AnnotationControll() {
  const { taskId, itemId } = useParams()
  const [item, setItem] = useState()
  const { message } = AntApp.useApp();
  const [isLoading, setIsLoading] = useState(false);
  const [fetchError, setError] = useState('');
  const [annotation, setAnnotation, _saveAnnotation] = useAnnotation()
  const fetchItem = useFetching(async () => {
    const response = await TasksService.getItemByIdAndTaskId(itemId, taskId);
    setItem(response.data.data)

  }, setIsLoading, setError)


  useEffect(() => {
    fetchItem()
  }, [taskId, itemId]);

  const saveAnnotation = async () => {
    if (annotation.category.length <= 1 & annotation.custom_field.length <= 1) {
      message.error('Choose category!')
      throw new Error("Choose category!");
    }

    await _saveAnnotation()
    message.success('Success!');
    await fetchItem()
    setAnnotation({'category': '', 'custom_field': ''})
  }

  return (
      <>
        <Layout>
          <NavigationToolBar
            item={item}
            saveAnnotation={saveAnnotation}
            resetAnnotation={ () => setAnnotation({'category':'', 'custom_field': ''})}
          />
          <Spin tip="Loading..." spinning={isLoading} size="large">
            <Annotator
              item={item}
              annotation={annotation}
              setAnnotation={setAnnotation}
            />
          </Spin>
        </Layout>
      </>
  )
}

export default AnnotationControll