import axios from "axios";

const axiosInstance = (token) => axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 5000,
    headers: {
        "Authorization": "Basic " + token
    }
});


export default class AuthService {
    static currentUser(token) {
        return axiosInstance(token).get('/users');
    }
}